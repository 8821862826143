var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"heading mb-0 text-left font-weight-bold",class:{ frozen: _vm.freezeFirstColumn }},[_c('span',[_vm._v(_vm._s(_setup.I18nFn("Forecasted Summary")))])]),_vm._v(" "),_c('table',{staticClass:"forecast-table",class:{
      frozen: _vm.freezeFirstColumn,
    },attrs:{"cellpadding":"0","cellspacing":"0"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"row-label text-left"},[_c('div',[_vm._v(_vm._s(_setup.I18nFn("Realized At")))])]),_vm._v(" "),_vm._l((_vm.displayedDateArr),function(date){return _c('th',{key:`header_${date.calendarYear}`,staticClass:"text-right"},[_vm._v("\n          "+_vm._s(_setup.formatDate(date))+"\n        ")])})],2)]),_vm._v(" "),_c('tbody',_vm._l((_vm.displayedRowArr),function(row,idx){return _c('tr',{key:_vm.options.getRowKey(row),class:_setup.generateRowClasses(row, idx, _vm.displayedRowArr)},[_c('td',{staticClass:"row-label",class:_setup.generateTitleCellClasses(row)},[(_vm.tableRowLabels?.[row.key])?_c('div',[_vm._v("\n            "+_vm._s(_setup.I18nFn(_vm.tableRowLabels?.[row.key]))+"\n          ")]):_vm._e()]),_vm._v(" "),_vm._l((_vm.displayedDateArr),function(date,i){return _c('td',{key:`${row.key}_${date?.dateKey}`,staticClass:"text-right",class:_setup.generateCommonCellClasses(
              _vm.userInputRows,
              date,
              row,
              _setup.getCellObj(row.key, date.dateKey, i)
            )},[(_setup.getCellObj(row.key, date.dateKey, i)?.unauth)?_c(_setup.UnauthorizedIcon):(_vm.options.displayInput(_vm.userInputRows, date, row.key))?[_c(_setup.NumericInput,{attrs:{"initial-value":_setup.scaleInputValue(
                  _setup.getCellValue(row.key, date.dateKey),
                  row,
                  date.dateKey
                ),"calendar-year":date.calendarYear,"suffix":row.inputSuffix,"disabled":_vm.previewMode},on:{"valueChange":(v, c) => _setup.handleValueChange(v, c, row.key)}})]:(
              _setup.isValidCell(row, date.dateKey, _vm.mostRecentActualDateKeyStr)
            )?_c('span',[_c(_setup.PeCell,{attrs:{"value":_setup.getCellValue(row.key, date.dateKey),"unit-id":_setup.getCellObj(row.key, date.dateKey, i)?.u,"ex-rate":_vm.options.getExRate(_setup.getCellObj(row.key, date.dateKey, i)),"cell-currency":_setup.getCellObj(row.key, date.dateKey, i)?.iso,"hide":_setup.getCellObj(row.key, date.dateKey, i)?.hide,"format":row.format,"desired-unit":_vm.desiredUnit,"formatter":_vm.formatter,"currency":_vm.currentIsoCurrency}})],1):_vm._e()],2)})],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }