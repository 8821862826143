import useNumberFormatters from "~/functions/useNumberFormatters"

const { formatValueBasedOnType } = useNumberFormatters()

const formatValue = (
  value,
  format,
  nativeCurrencyFormatter,
  usdCurrencyFormatter,
  useUsdCurrencyFormatter
) => {
  if (format === "currency") {
    return formatValueBasedOnType({
      type: format,
      value,
      nativeCurrencyFormatter,
      useUsdCurrencyFormatter,
      usdCurrencyFormatter,
    })
  }
  return formatValueBasedOnType({
    type: format,
    value,
  })
}

const shouldDisplayInput = (userInputRows, dateObj, key) => {
  return (
    userInputRows.find((item) => item.metricKey === key) && dateObj.isEstimate
  )
}

const getRowKey = (row) => {
  if (row.key === "blank") {
    return `blank_${Math.random()}`
  }
  return row.key
}

export { formatValue, shouldDisplayInput, getRowKey }
