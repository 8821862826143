import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.to-fixed.js";
import { watch } from "vue";
import { isString } from "lodash";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'NumericInput',
  props: {
    initialValue: {
      type: [String, Number],
      "default": 0
    },
    calendarYear: {
      type: Number,
      "default": null
    },
    suffix: {
      type: String,
      "default": ""
    },
    allowNegativeNumbers: {
      type: Boolean,
      "default": true
    },
    disabled: {
      type: Boolean,
      "default": false
    }
  },
  emits: ["valueChange"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var toTwoDigitFloat = function toTwoDigitFloat(number) {
      var _numberAsStr$split$;
      if (number === 0) {
        return 0;
      }
      if (!number) {
        return;
      }
      var numberAsStr = String(number);
      if (((_numberAsStr$split$ = numberAsStr.split(".")[1]) === null || _numberAsStr$split$ === void 0 ? void 0 : _numberAsStr$split$.length) < 2) {
        return numberAsStr;
      }
      if (isString(number)) {
        return Number(number).toFixed(2);
      }
      return Number(number.toFixed(2));
    };
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive;
    var focused = ref(false);
    var inputFinalValue = ref(toTwoDigitFloat(props.initialValue));
    watch(inputFinalValue, function (newVal) {
      emit("valueChange", newVal, props.calendarYear);
    });
    var handleChange = function handleChange(e) {
      var value = e.target.value;
      var splitByCommas = value.split(",");
      var isNegativeValueAndNotAllowed = !props.allowNegativeNumbers && value < 0;
      if (isNegativeValueAndNotAllowed) {
        e.target.value = 0;
      } else if (splitByCommas.length > 1) {
        var splittedFinalVal = Number(splitByCommas[0]);
        inputFinalValue.value = splittedFinalVal;
      } else {
        inputFinalValue.value = Number(value);
      }
    };
    var handleKeyPress = function handleKeyPress(event) {
      var value = event.target.value;
      if (!focused.value && value) {
        event.target.value = "";
        focused.value = true;
      }
    };
    var handleFocusOut = function handleFocusOut() {
      focused.value = false;
    };
    return {
      __sfc: true,
      props: props,
      toTwoDigitFloat: toTwoDigitFloat,
      darkActive: darkActive,
      emit: emit,
      focused: focused,
      inputFinalValue: inputFinalValue,
      handleChange: handleChange,
      handleKeyPress: handleKeyPress,
      handleFocusOut: handleFocusOut
    };
  }
};