import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.bold.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import UnauthorizedIcon from "~/components/UnauthorizedIcon.vue";
import NumericInput from "~/components/inputs/NumericInput.vue";
import PeCell from "~/components/valuationModels/Pe/PeCell.vue";
import useBaseUtils from "~/functions/useBaseUtils";
import { findExRate } from "~/utils/ciq";
import { getYearKey } from "~/utils/tools";
import { shouldDisplayInput, getRowKey } from "~/utils/constants/valuationModel";
export default {
  __name: 'PeForecastedSummary',
  props: {
    displayedDateArr: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    displayedRowArr: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    tableData: {
      type: Object,
      "default": function _default() {}
    },
    tableRowLabels: {
      type: Object,
      "default": function _default() {}
    },
    userInputRows: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    currentIsoCurrency: {
      type: String,
      "default": "USD"
    },
    formatter: {
      type: Function,
      "default": function _default(value) {
        return value;
      }
    },
    desiredUnit: {
      type: Number,
      "default": 1
    },
    mostRecentActualDateKeyStr: {
      type: String,
      "default": ""
    },
    options: {
      type: Object,
      "default": function _default() {
        return {
          getExRate: findExRate,
          displayInput: shouldDisplayInput,
          getRowKey: getRowKey
        };
      }
    },
    freezeFirstColumn: {
      type: Boolean,
      "default": false
    },
    assumptionCaseIndex: {
      type: Number,
      "default": 0
    },
    previewMode: {
      type: Boolean,
      "default": false
    }
  },
  emits: ["valueChange"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var currentModelId = computed(function () {
      return $store.state.valuationModel.currentModelId;
    });
    var handleValueChange = function handleValueChange(value, calendarYear, id) {
      emit("valueChange", value, calendarYear, id);
    };
    var scaleInputValue = function scaleInputValue(val, rowObj, dateKeyStr) {
      var inputMetaData = props.userInputRows.find(function (item) {
        return item.metricKey === rowObj.key;
      });
      var multiplyBy = (inputMetaData === null || inputMetaData === void 0 ? void 0 : inputMetaData.multiplyBy) || 1;
      if (currentModelId.value) {
        var _$store$state$valuati;
        var _val = (_$store$state$valuati = $store.state.valuationModel.data.userInputsDataArray[props.assumptionCaseIndex]) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati[rowObj.key]) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati[dateKeyStr];
        return _val * multiplyBy;
      }
      var scaled = val * multiplyBy;
      return scaled;
    };
    var isFirstHighlightRow = function isFirstHighlightRow(idx, rowArr) {
      var firstHighlightIndex = rowArr.indexOf(rowArr.find(function (item) {
        return item.highlight;
      }));
      return idx === firstHighlightIndex;
    };
    var findLastHighlightIndex = function findLastHighlightIndex(array) {
      var reversedArray = _toConsumableArray(array).reverse();
      var reversedIndex = reversedArray.findIndex(function (obj) {
        return obj.highlight;
      });
      return reversedIndex === -1 ? -1 : array.length - 1 - reversedIndex;
    };
    var isLastHighlightRow = function isLastHighlightRow(idx, rowArr) {
      var lastHighlightIndex = findLastHighlightIndex(rowArr);
      return idx === lastHighlightIndex;
    };
    var generateRowClasses = function generateRowClasses(row, idx, displayedRowArr) {
      return {
        "yellow-highlight": row === null || row === void 0 ? void 0 : row.highlight,
        first: (row === null || row === void 0 ? void 0 : row.highlight) && isFirstHighlightRow(idx, displayedRowArr),
        last: (row === null || row === void 0 ? void 0 : row.highlight) && isLastHighlightRow(idx, displayedRowArr)
      };
    };
    var generateTitleCellClasses = function generateTitleCellClasses(row) {
      return {
        "font-weight-bold": row.bold,
        "text-left": true
      };
    };
    var generateCommonCellClasses = function generateCommonCellClasses(userInputRows, date, row, cellObj) {
      return {
        "red--text": !props.options.displayInput(userInputRows, date, row.key) && (cellObj === null || cellObj === void 0 ? void 0 : cellObj.v) < 0,
        "font-weight-bold": !props.options.displayInput(userInputRows, date, row.key) && row.bold
      };
    };
    var formatDate = function formatDate(dateObj) {
      var isEstimate = dateObj.isEstimate;
      var suffix = isEstimate ? "E" : "A";
      var date = isEstimate ? dateObj.fiscalDate : dateObj.formattedFiperiodenddate;
      return "".concat(date).concat(suffix);
    };
    var getCellObj = function getCellObj(rowKey, dateKey) {
      var _props$tableData2;
      if (rowKey === "valuationEpsNormalized") {
        var _props$tableData;
        var newDateKey = getYearKey(dateKey, 1);
        return (_props$tableData = props.tableData) === null || _props$tableData === void 0 || (_props$tableData = _props$tableData[rowKey]) === null || _props$tableData === void 0 ? void 0 : _props$tableData[newDateKey];
      }
      return (_props$tableData2 = props.tableData) === null || _props$tableData2 === void 0 || (_props$tableData2 = _props$tableData2[rowKey]) === null || _props$tableData2 === void 0 ? void 0 : _props$tableData2[dateKey];
    };
    var getCellValue = function getCellValue(rowKey, dateKey) {
      var _props$tableData4;
      if (rowKey === "valuationEpsNormalized") {
        var _props$tableData3;
        var newDateKey = getYearKey(dateKey, 1);
        return (_props$tableData3 = props.tableData) === null || _props$tableData3 === void 0 || (_props$tableData3 = _props$tableData3[rowKey]) === null || _props$tableData3 === void 0 || (_props$tableData3 = _props$tableData3[newDateKey]) === null || _props$tableData3 === void 0 ? void 0 : _props$tableData3.v;
      }
      return (_props$tableData4 = props.tableData) === null || _props$tableData4 === void 0 || (_props$tableData4 = _props$tableData4[rowKey]) === null || _props$tableData4 === void 0 || (_props$tableData4 = _props$tableData4[dateKey]) === null || _props$tableData4 === void 0 ? void 0 : _props$tableData4.v;
    };
    var isValidCell = function isValidCell(row, dateKey, mostRecentActualDateKeyStr) {
      var rowKey = row.key;
      var hideActuals = Boolean(row === null || row === void 0 ? void 0 : row.hideActuals);
      var cellValue = getCellValue(rowKey, dateKey);
      if (rowKey === "cumulativeDividendsPerShare") {
        return isFinite(cellValue) && cellValue !== 0;
      }
      if (rowKey === "stockPrice") {
        var oneYearAhead = getYearKey(mostRecentActualDateKeyStr, 1);
        return isFinite(cellValue) && dateKey <= oneYearAhead;
      }
      if (hideActuals) {
        return isFinite(cellValue) && dateKey > props.mostRecentActualDateKeyStr;
      }
      return isFinite(cellValue);
    };
    return {
      __sfc: true,
      props: props,
      emit: emit,
      I18nFn: I18nFn,
      $store: $store,
      currentModelId: currentModelId,
      handleValueChange: handleValueChange,
      scaleInputValue: scaleInputValue,
      isFirstHighlightRow: isFirstHighlightRow,
      findLastHighlightIndex: findLastHighlightIndex,
      isLastHighlightRow: isLastHighlightRow,
      generateRowClasses: generateRowClasses,
      generateTitleCellClasses: generateTitleCellClasses,
      generateCommonCellClasses: generateCommonCellClasses,
      formatDate: formatDate,
      getCellObj: getCellObj,
      getCellValue: getCellValue,
      isValidCell: isValidCell,
      UnauthorizedIcon: UnauthorizedIcon,
      NumericInput: NumericInput,
      PeCell: PeCell,
      getRowKey: getRowKey
    };
  }
};